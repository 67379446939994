import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  Icon,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  // tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import HotelItem from "./components/HotelItem";
import TotalPrices from "./components/TotalPrices";
import { useFormContext } from "../FormContextProvide";
import PrintIcon from "@mui/icons-material/Print";
import Post from "lib/api/post";
import { PREVIOUS, SUBMIT, MODIFY } from "../const";
import { useNavigate, useParams } from "react-router-dom";
import Put from "lib/api/put";
import { INVOICE, REQUEST, VOUCHER } from "../ExtraCostsTab/components/const";
import { useEffect, useState } from "react";
import { SELECT_USER } from "store/slices/profile";
import { BASE_URL } from "lib/constants/urls";
import { MODAL_STYLE } from "../style";
import { CANCEL, CANCELED, SELECT_AGENCY_RESELLER, SEND } from "../HotelTab/const";
// import styled from "@emotion/styled";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { IconButton } from "@material-ui/core";
import draftToHtml from "draftjs-to-html";
type Props = {
  next: () => void;
  back: () => void;
  activeStep: number;
  formSubmit: () => void;
};

const ConfirmationTabContent = ({ next, back, activeStep, formSubmit }: Props) => {
  const { formContext, setFormContext } = useFormContext();
  const [open, setOpen] = useState(false);
  const [btnType, setBtnType] = useState({ status: false, type: null });
  const { id } = useParams();
  const user = SELECT_USER();
  const navigate = useNavigate();
  const handleSubmit = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    const form = {
      ...formContext,
      session_id: user?.session_id,
      user_id: user?.uid,
    };
    if (!id) {
      await Post.addReservation(form)
        .then((res) => {
          formSubmit();
          setFormContext({
            hotel_note: "",
            operation_note: "",
            status: 1,
            rooms: [],
            extras: [],
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      const newRooms = form?.rooms?.map((item: any) => {
        const { financials, ...rest } = item;
        return {
          ...rest,
          financial: financials || item?.financial,
        };
      });
      await Put.updateReservation({ ...form, reservation_id: id, rooms: newRooms })
        .then((res) => {
          formSubmit();
          setFormContext({
            hotel_note: "",
            operation_note: "",
            status: 1,
            rooms: [],
            extras: [],
          });
          navigate("/reservation/archive");
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [selectedHotels, setSelectedHotels] = useState([]);
  const [selectedHotelsMails, setSelectedHotelsMails] = useState("");
  const [selectedSeller, setSelectedSeller] = useState([]);
  const [prefixT, setPrefixT] = useState(null);
  const [subject, setSubject] = useState(null);
  const [mailcc, setMailcc] = useState(null);
  const [mailbcc, setMailbcc] = useState(null);
  const [type, setType] = useState(null);
  useEffect(() => {
    setSubject(formContext?.voucher);
    setMailcc(formContext?.mail_options?.cc);
    setMailbcc(formContext?.mail_options?.bcc);
    setPrefixT(formContext?.mail_options?.signitures.find((obj: any) => obj.prefix === "T"));
  }, [formContext?.voucher, formContext?.mail_options]);

  const handleHotelChange = (event: any, value: any) => {
    setSelectedHotels(value);
    setSelectedHotelsMails(value.map((hotel: any) => hotel.label).join(", "));
  };
  const handleSellerChange = (event: any, value: any) => {
    setSelectedSeller(value);
  };
  // Handle editor state change
  const onEditorStateChange = (newState: EditorState) => {
    setEditorState(newState);
  };
  const getEditorContentAsHTML = () => {
    if (editorState) {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const htmlContent = draftToHtml(rawContentState);
      return htmlContent;
    }
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  // const StyledTableCell = styled(TableCell)(({ theme }) => ({
  //   [`&.${tableCellClasses.head}`]: {
  //     color: "gray",
  //   },
  //   [`&.${tableCellClasses.body}`]: {
  //     fontSize: 14,
  //   },
  // }));
  console.log({ formContext });
  const hotelOptions = formContext?.mails?.[0].map((email: any) => {
    return {
      label: `${email.value} ( ${email?.fullname} )`,
      value: email.hotel_id,
    };
  });
  const resellerOptions = formContext?.mails?.[1].map((email: any) => {
    return {
      label: email.value,
      value: email.value,
    };
  });
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...MODAL_STYLE, width: "80vw", height: "80vh" }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography>{SELECT_AGENCY_RESELLER}</Typography>
            <IconButton onClick={handleClose}>
              <Icon>close</Icon>
            </IconButton>
          </Box>

          <Divider sx={{ borderWidth: "1px" }} />

          {/* Form Section */}
          <Box sx={{ my: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  options={hotelOptions}
                  getOptionLabel={(option: any) => option.label}
                  value={selectedHotels}
                  onChange={handleHotelChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Hotel Mails: "
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          minHeight: "45px",
                          ".MuiInputBase-input": {
                            height: "100%",
                            padding: "10px 14px",
                          },
                        },
                      }}
                    />
                  )}
                  sx={{
                    "& .MuiAutocomplete-endAdornment": {
                      top: "unset",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* <TextField label="Reseller Mails: " fullWidth /> */}
                <Autocomplete
                  multiple
                  options={resellerOptions}
                  getOptionLabel={(option: any) => option.label}
                  onChange={handleSellerChange}
                  value={selectedSeller}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Reseller Mails: "
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          minHeight: "45px",
                          ".MuiInputBase-input": {
                            height: "100%",
                            padding: "10px 14px",
                          },
                        },
                      }}
                    />
                  )}
                  sx={{
                    "& .MuiAutocomplete-endAdornment": {
                      top: "unset",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Emails"
                  fullWidth
                  InputLabelProps={{
                    shrink: selectedHotelsMails ? true : false,
                  }}
                  onChange={(value) => {
                    setSelectedHotelsMails(value.target.value);
                  }}
                  value={selectedHotelsMails}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Subject"
                  onChange={(value) => {
                    setSubject(value.target.value);
                  }}
                  value={subject}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="cc"
                  InputLabelProps={{
                    shrink: mailcc ? true : false,
                  }}
                  value={mailcc}
                  onChange={(value: any) => {
                    setMailcc(value.target.value);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="bcc"
                  InputLabelProps={{
                    shrink: mailbcc ? true : false,
                  }}
                  value={mailbcc}
                  onChange={(value: any) => {
                    setMailbcc(value.target.value);
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>

            {/* Text Editor */}
            <Box sx={{ mt: 3 }}>
              <Box
                sx={{
                  border: "1px solid #ccc",
                  minHeight: "200px",
                  padding: "10px",
                  borderRadius: "4px",
                }}
              >
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={onEditorStateChange}
                />
              </Box>
            </Box>
          </Box>

          <Divider sx={{ borderWidth: "1px" }} />

          <Grid display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6} alignItems={"center"}>
              <Stack direction="row" py={2} justifyContent="space-evenly">
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    color: "white !important",
                    backgroundColor: "#1A73E8 !important",
                    ":hover": {
                      backgroundColor: "#1459B7 !important",
                    },
                    borderRadius: "5px !important",
                    paddingLeft: "24px !important",
                    paddingRight: "24px !important",
                  }}
                  onClick={async () => {
                    const combinedText = JSON.parse(prefixT?.footer_invoice)
                      ?.blocks.map((item: any) => item.text)
                      .join("\n");
                    const contentState = ContentState.createFromText(combinedText);
                    const newEditorState = EditorState.createWithContent(contentState);
                    setEditorState(newEditorState);
                    setType("NEW RESERVATION");
                  }}
                >
                  NEW
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    color: "#1767d1 !important",
                    backgroundColor: "#fff !important",
                    ":hover": {
                      backgroundColor: "#d5d5d !important",
                    },
                    borderRadius: "5px !important",
                    paddingLeft: "24px !important",
                    paddingRight: "24px !important",
                  }}
                >
                  <a
                    href={`${BASE_URL}/reservations/getRequestPdf?sid=${user?.session_id}&tk=${user?.accessToken}&reservation_id=${id}&type=request&title=NEW%20RESERVATION`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#1767d1" }}
                  >
                    <Icon>download</Icon>
                  </a>
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    color: "white !important",
                    backgroundColor: "#1A73E8 !important",
                    ":hover": {
                      backgroundColor: "#1459B7 !important",
                    },
                    borderRadius: "5px !important",
                    paddingLeft: "24px !important",
                    paddingRight: "24px !important",
                  }}
                  onClick={async () => {
                    const combinedText = JSON.parse(prefixT?.footer_change_request)
                      ?.blocks.map((item: any) => item.text)
                      .join("\n");
                    const contentState = ContentState.createFromText(combinedText);
                    const newEditorState = EditorState.createWithContent(contentState);
                    setEditorState(newEditorState);
                    setType("CHANGE");
                  }}
                >
                  CHANGED
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    color: "#1767d1 !important",
                    backgroundColor: "#fff !important",
                    ":hover": {
                      backgroundColor: "#d5d5d5 !important",
                    },
                    borderRadius: "5px !important",
                    paddingLeft: "24px !important",
                    paddingRight: "24px !important",
                  }}
                >
                  <a
                    href={`${BASE_URL}/reservations/getRequestPdf?sid=${user?.session_id}&tk=${user?.accessToken}&reservation_id=${id}&type=request&title=CHANGED`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#1767d1" }}
                  >
                    <Icon>download</Icon>
                  </a>
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    color: "white !important",
                    backgroundColor: "#1A73E8 !important",
                    ":hover": {
                      backgroundColor: "#1459B7 !important",
                    },
                    borderRadius: "5px !important",
                    paddingLeft: "24px !important",
                    paddingRight: "24px !important",
                  }}
                  onClick={async () => {
                    const combinedText = JSON.parse(prefixT?.footer_new_request)
                      ?.blocks.map((item: any) => item.text)
                      .join("\n");
                    const contentState = ContentState.createFromText(combinedText);
                    const newEditorState = EditorState.createWithContent(contentState);
                    setEditorState(newEditorState);
                    setType("CANCEL");
                  }}
                >
                  {CANCELED}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    color: "white !important",
                    backgroundColor: "#fff !important",
                    ":hover": {
                      backgroundColor: "#fff !important",
                    },
                    borderRadius: "5px !important",
                    paddingLeft: "24px !important",
                    paddingRight: "24px !important",
                  }}
                >
                  <a
                    href={`${BASE_URL}/reservations/getRequestPdf?sid=${user?.session_id}&tk=${user?.accessToken}&reservation_id=${id}&type=request&title=CANCEL`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#1767d1" }}
                  >
                    <Icon>download</Icon>
                  </a>
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction="row" py={2} justifyContent="end">
                <Button
                  sx={{
                    color: "black !important",
                    backgroundColor: "#fff !important",
                    ":hover": {
                      backgroundColor: "#fff !important",
                    },
                    borderRadius: "5px !important",
                    paddingLeft: "24px !important",
                    paddingRight: "24px !important",
                    marginRight: "5px !important",
                  }}
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                >
                  {CANCEL}
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    color: "white !important",
                    backgroundColor: "#1A73E8 !important",
                    ":hover": {
                      backgroundColor: "#1459B7 !important",
                    },
                    borderRadius: "5px !important",
                    paddingLeft: "24px !important",
                    paddingRight: "24px !important",
                  }}
                  onClick={async () => {
                    const htmlContent = getEditorContentAsHTML();
                    await Post.reservationMail({
                      user_id: user?.uid,
                      session_id: user?.session_id,
                      reservation_id: id,
                      type: "request",
                      title: type,
                      cc: mailcc,
                      bcc: mailbcc,
                      to: selectedHotels?.map((hotel: any) => {
                        const text = hotel?.label?.indexOf("(");
                        const mail = text !== -1 ? hotel?.label?.substring(0, text) : hotel?.label;
                        return { mail, hotel_id: hotel?.value };
                      }),
                      content: htmlContent,
                      subject,
                    }).then((res) => {
                      handleClose();
                      setEditorState(EditorState.createEmpty());
                      setSelectedHotels([]);
                      setSelectedHotelsMails("");
                      setSelectedSeller([]);
                    });
                  }}
                >
                  {SEND}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Grid container>
        {formContext?.rooms
          ?.sort((a: any, b: any) => a.hotel_title - b.hotel_title)
          .map((room: any, index: number) => {
            return (
              <Grid key={index + 1} xs={12} item>
                <HotelItem room={room} index={index} formContext={formContext} />
              </Grid>
            );
          })}
        <Grid xs={12} item>
          <TotalPrices />
        </Grid>
        <Grid xs={12} item direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
          <Button
            variant="contained"
            size="medium"
            style={{ marginLeft: "4px", marginRight: "4px" }}
            color="secondary"
          >
            <PrintIcon />
          </Button>
          {id && (
            <>
              <Button
                variant="contained"
                size="medium"
                style={{ marginLeft: "4px", marginRight: "4px" }}
                color="primary"
                onClick={() => {
                  setBtnType({ status: true, type: "invoice" });
                }}
              >
                <PrintIcon /> {INVOICE}
              </Button>
              <Button
                variant="contained"
                size="medium"
                style={{ marginLeft: "4px", marginRight: "4px" }}
                color="primary"
                onClick={() => {
                  setBtnType({ status: true, type: "request" });
                }}
              >
                <PrintIcon /> {REQUEST}
              </Button>
              <Button
                variant="contained"
                size="medium"
                style={{ marginLeft: "4px", marginRight: "4px" }}
                color="primary"
                onClick={() => {
                  setBtnType({ status: true, type: "voucher" });
                }}
              >
                <PrintIcon /> {VOUCHER}
              </Button>
              <Button
                variant="contained"
                size="medium"
                style={{ marginLeft: "4px", marginRight: "4px" }}
                color="primary"
                onClick={() => {
                  handleOpen();
                }}
              >
                <Icon>email</Icon>
              </Button>
            </>
          )}
        </Grid>
        {id && (
          <Grid xs={12} item direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
            <Collapse in={btnType?.status} timeout="auto" unmountOnExit>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Hotel</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formContext?.hotels?.map((hotel: any) => {
                      return (
                        <TableRow>
                          <TableCell>{hotel?.hotel_name || hotel?.fullname}</TableCell>
                          <TableCell>
                            {btnType?.type === "invoice" ? (
                              <Button
                                variant="contained"
                                size="medium"
                                style={{ marginLeft: "4px", marginRight: "4px" }}
                                color="primary"
                                // onClick={() => {
                                //   setBtnType({ status: true, type: "invoice" });
                                // }}
                              >
                                <a
                                  href={`${BASE_URL}/reservations/getRequestPdf?sid=${user?.session_id}&tk=${user?.accessToken}&reservation_id=${id}&type=invoice&hotel_id=${hotel?.id}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ color: "#fff" }}
                                >
                                  <PrintIcon /> {INVOICE}
                                </a>
                              </Button>
                            ) : btnType?.type === "request" ? (
                              <Button
                                variant="contained"
                                size="medium"
                                style={{ marginLeft: "4px", marginRight: "4px" }}
                                color="primary"
                              >
                                <a
                                  href={`${BASE_URL}/reservations/getRequestPdf?sid=${user?.session_id}&tk=${user?.accessToken}&reservation_id=${id}&type=request&hotel_id=${hotel?.id}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ color: "#fff" }}
                                >
                                  <PrintIcon /> {REQUEST}
                                </a>
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                size="medium"
                                style={{ marginLeft: "4px", marginRight: "4px" }}
                                color="primary"
                                onClick={() => {
                                  setBtnType({ status: true, type: "voucher" });
                                }}
                              >
                                <a
                                  href={`${BASE_URL}/reservations/getRequestPdf?sid=${user?.session_id}&tk=${user?.accessToken}&reservation_id=${id}&type=voucher&hotel_id=${hotel?.id}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ color: "#fff" }}
                                >
                                  <PrintIcon /> {VOUCHER}
                                </a>
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack direction="row" py={2} justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              disabled={activeStep === 0}
              onClick={back}
              sx={{ mr: 1 }}
            >
              {PREVIOUS}
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              {id ? MODIFY : SUBMIT}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default ConfirmationTabContent;
