import { Axios } from "utils/axios";
import {
  BoardDetailsFormType,
  HotelListFormType,
  ReservationsBoardFormType,
  ReservationsExcelBoardFormType,
  UserAddFormType,
  UserListFormType,
  checkContactFormType,
} from "./postInterfaces";
import { AddContactType, RoomType } from "./types";
import { SideType } from "lib/types/api-type";

export interface AdminType {
  session_id: number;
  user_id: number;
}

export interface SignInFormType {
  passwd: string;
  username: string;
}
const signIn = (form: SignInFormType) => Axios.post("/authenticate", form);

export interface OperationsFormType extends AdminType {}
const operations = (form: OperationsFormType) => Axios.post("/operations/loadRequirements", form);

export interface OperationFromType extends AdminType {
  // date: "2022-06-28"
  date: string;
}

const getPassengers = (form: OperationFromType) => Axios.post("/operations/loadOperation", form);

export interface SyncBatchFormType extends AdminType {
  flights: string;
  hotels: string;
  indras: string;
}

const syncBatch = (form: SyncBatchFormType) => Axios.post(`/operations/syncBatch`, form);

export interface HotelTypeFormType {
  order: string;
  title: string;
  type_code: string;
  type_level: number;
}
const hotelType = (form: HotelTypeFormType) => Axios.post(`/hotels/addHotelType`, form);

const hotel = (form: any) => Axios.post("/hotels/addHotel", form);

const searchHotelType = (session_id: number, user_id: number) =>
  Axios.post(`hotels/searchHotelType`, {
    count: 10000,
    key: "",
    page: 1,
    session_id,
    user_id,
    type_level: 0,
  });

const searchHotel = (session_id: number, user_id: number, key: string) =>
  Axios.post(`hotels/searchHotel`, {
    count: 10000,
    key,
    page: 1,
    session_id,
    user_id,
    type_level: 0,
  });

const hotelProfile = (session_id: number, user_id: number, hotel_id: number) =>
  Axios.post(`hotels/hotelProfile`, {
    page: 1,
    count: 10,
    hotel_id,
    session_id,
    user_id,
  });

const getHotel = (session_id: number, user_id: number, hotel_id: number) =>
  Axios.post(`hotels/gethotel`, {
    session_id,
    user_id,
    hotel_id,
  });

const overNight = (
  from: string,
  to: string,
  session_id: number,
  user_id: number,
  hotel_id: number
) =>
  Axios.post(`reservations/overnight`, {
    to,
    from,
    hotel_id,
    session_id,
    user_id,
  });

const inHouse = (date: string, session_id: number, user_id: number, hotel_id: number) =>
  Axios.post(`reservations/inhouse`, {
    date,
    hotel_id,
    session_id,
    user_id,
  });

const checkIn = (date: string, session_id: number, user_id: number, hotel_id: number) =>
  Axios.post(`reservations/checkin`, {
    date,
    hotel_id,
    session_id,
    user_id,
  });

const checkOut = (date: string, session_id: number, user_id: number, hotel_id: number) =>
  Axios.post(`reservations/checkout`, {
    date,
    hotel_id,
    session_id,
    user_id,
  });

// count: 10
// key: "ye"
// page: 1
// session_id: 378
// user_id: 7185
// zone_type: "district"

const saveBatch = (form: any) => Axios.post("/operations/savebatch", form);

const searchZone = (form: any) => Axios.post(`/zones/searchZone`, form);

export interface GethotelflightTimesFormType extends AdminType {
  date: string;
}

const gethotelflighttimes = (form: any) => Axios.post(`/operations/gethotelflighttimes`, form);

export interface VoucherDescriptionType extends AdminType {
  date: string;
  description: string;
  voucher_id: string;
  side: SideType;
  desc_user_id: number;
}
const VoucherDescription = (form: VoucherDescriptionType) =>
  Axios.post(`/operations/setVoucherDesc`, form);

const addContact = (form: AddContactType) => Axios.post(`/hotels/addContact`, form);

const addRoom = (form: RoomType) => Axios.post(`/hotels/addRoom`, form);

const deleteHotel = (session_id: number, user_id: number, hotel_id: number) =>
  Axios.post(`hotels/deleteHotel`, {
    hotel_id,
    session_id,
    user_id,
  });

export interface SearchReservationFormType extends AdminType {
  fromdate: string | null;
  todate: string | null;
  in_house: string | null;
  check_in: string | null;
  check_out: string | null;
  status: number | null;
  voucher: number | null;
  hotel_id: number | null;
  reseller_id: number | null;
  arrival_flight: number | null;
  departure_flight: number | null;
  passenger_id: number | null;
  partner_id: number | null;
  page: number;
}
const searchReservation = (form: SearchReservationFormType) =>
  Axios.post(`/reservations/searchReservation`, form);

const reservationsBoard = (form: ReservationsBoardFormType) =>
  Axios.post(`/reservations/board`, form);

  const reservationsExcelBoard = (form: ReservationsExcelBoardFormType) =>
  Axios.post(`/reservations/checkinExcelReport`, form);
export interface SearchPasssengersFormType extends AdminType {
  count: number;
  key: string;
  page: number;
}

const searchPassenengers = (form: SearchPasssengersFormType) =>
  Axios.post(`/users/searchByKey`, form);

const searchByCompany = (form: SearchPasssengersFormType) =>
  Axios.post(`/users/searchByCompany`, form);

export interface SearchFlightFormType extends AdminType {
  count: number;
  key: string;
  page: number;
}

const searchFlight = (form: any) => Axios.post(`/flights/searchFlight`, form);

export const hotelList = (form: HotelListFormType) => Axios.post(`/hotels/searchHotel`, form);

export const usersList = (form: UserListFormType) => Axios.post(`/users/searchByKey`, form);
export const usersAdd = (form: UserAddFormType) => Axios.post(`/users/createUser`, form);
export const boardDetails = (form: BoardDetailsFormType) =>
  Axios.post(`/reservations/boardDetails`, form);

const oprationReport = (form: any) => Axios.post(`/operations/operationReport`, form);

const loadCarLeader = (form: AdminType) => Axios.post(`operations/loadcarleader`, form);

const checkVoucher = (form: any) => Axios.post("/reservations/checkVoucher", form);

const createUser = (form: any) => Axios.post("users/createUser", form);
const checkContact = (form: checkContactFormType) => Axios.post("users/checkContact", form);
const loadRequirements = (form: any) => Axios.post("/reservations/loadRequirements", form);
const searchAirline = (form: any) => Axios.post("/flights/searchAirline", form);
const addAirline = (form: any) => Axios.post("/flights/addAirline", form);
const addFlight = (form: any) => Axios.post("/flights/addFlight", form);
const addReservation = (form: any) => Axios.post("/reservations/addReservation", form);
const loadReservation = (form: any) => Axios.post("/reservations/loadReservation", form);
const reservationMail = (form: any) => Axios.post("/reservations/mail", form);

const Post = {
  signIn,
  operations,
  getPassengers,
  syncBatch,
  hotelType,
  hotel,
  saveBatch,
  searchHotelType,
  searchZone,
  gethotelflighttimes,
  VoucherDescription,
  addContact,
  addRoom,
  hotelProfile,
  inHouse,
  overNight,
  checkIn,
  checkOut,
  deleteHotel,
  searchHotel,
  getHotel,
  searchReservation,
  searchPassenengers,
  searchFlight,
  searchByCompany,
  reservationsBoard,
  hotelList,
  usersList,
  boardDetails,
  oprationReport,
  loadCarLeader,
  checkVoucher,
  createUser,
  checkContact,
  loadRequirements,
  searchAirline,
  addAirline,
  addFlight,
  addReservation,
  loadReservation,
  usersAdd,
  reservationsExcelBoard,
  reservationMail
};

export default Post;
